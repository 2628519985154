<template>
  <div class="page-content">
    <img src="../../static/oa-img/tu02.jpg" />
    <div v-if="status == 'audited'">
      <h1>问卷已审核</h1>
    </div>
    <div v-else-if="status == 'auditing'">
      <h1>问卷审核中</h1>
      <p>问卷已提交审核，请耐心等待审核结果</p>
    </div>
    <div v-else>
      <h1>问卷提交成功</h1>
      <p>问卷已提交审核，请耐心等待审核结果</p>
    </div>
    <div class="closebtn" @click="closePage">关闭</div>
  </div>
</template>
 
<style src="../../static/oa-img/css/step-complete.css"  scoped></style>
 
<script>
import wx from 'weixin-js-sdk'
export default {
  data() {
    return {
      status: ''
    }
  },
  mounted() {
    this.status = this.$route.params.pathMatch
  },
  methods: {
    closePage() {
      console.log('关闭页面')
      wx.closeWindow()
    }
  }
}
</script>
